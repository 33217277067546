import { useRouter } from 'next/router';
import { Icon, NotificationBarProps, Theme } from '@pelando/components';
import { useRef } from 'react';
import { CirclePlus, Flag, Sparkles } from '@pelando/fontawesome/icons';
import dynamic from 'next/dynamic';
import { useOnboardingContextAlertRegister } from '@/presentation/hooks/useOnboardingContextAlertRegister';

import {
  FloatingElementVerticalPosition,
  FloatingElementHorizontalPosition,
} from '@pelando/components/components/FloatingElementWrapper/consts';
import { getImagePath } from '@/presentation/services/image';
import { useAlertSuggestionsModal } from '@/presentation/hooks/useShowAlertSuggestionsModal';
import { NotificationItem } from '@/domain/entities/Notification';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { getCashbackAvailableBalance } from '@/infra/api/cashback/endpoints';
import { getAddOfferUrl, getWishlistUrl } from '../../../../services/urls';
import useTopStoresMenuHandler from '../../hooks/useTopStoresMenuHandle';
import { CountBadgeIcon } from '../../../CountBadgeIcon';

import {
  HeaderRightCornerContainer,
  HeaderItem,
  PostDealIcon,
  HeaderList,
  AlertsIcon,
  TextIconTooltipStyle,
  HeaderNotificationItem,
} from './styles';
import { getLoggedMenuOptions, getMenuOptions } from '../options';
import { useTranslation } from '../../../../hooks/useTranslation';
import {
  isBlackFridayHeaderEnabled,
  isHeaderCommunitiesDisabled,
} from '../../../../services/featureFlags';
import { HeaderCategoriesItem } from '../HeaderCategoriesItem';

const HeaderDynamicItemLink = dynamic(
  () => import('./styles').then((mod) => mod.HeaderItemLink),
  { ssr: false }
);

const DEFAULT_UNLOGGED_AVATAR_IMAGE = 'logoBannerLogin.webp';

type HeaderRightCornerContentProps = {
  isLogged?: boolean;
  isLoading?: boolean;
  notificationBarProps: NotificationBarProps;
  userNickname: string;
  userImage: string;
  onSignInClick: () => void;
  logout: () => void;
  alertsCount?: number;
  hasContextAlertRegister?: boolean;
};

const CustomUserMenu = dynamic(() =>
  import('./styles').then((mod) => mod.CustomUserMenu)
);

const NotificationBar = dynamic(() =>
  import('@pelando/components/modules').then((mod) => mod.NotificationBar)
);

export const HeaderRightCornerContent = ({
  isLogged,
  notificationBarProps,
  userImage,
  userNickname,
  onSignInClick,
  logout,
  alertsCount,
  hasContextAlertRegister,
}: HeaderRightCornerContentProps) => {
  const { reload, pathname, locale } = useRouter();
  const { t } = useTranslation('header', 'onboardingContext');
  const { featuredStores, handleGetFeaturedStores } = useTopStoresMenuHandler();
  const isBlackFridayHeaderToggleEnabled = isBlackFridayHeaderEnabled();
  const buttonAlertRef = useRef<HTMLDivElement>(null);
  const { triggerAlertModal } = useAlertSuggestionsModal();
  const isCommunitiesHeaderDisabled = isHeaderCommunitiesDisabled();
  const { cashbackAvailableBalance } =
    useApiCache(getCashbackAvailableBalance.cache$) || {};
  const availableBalanceCashback = cashbackAvailableBalance?.balance || 0;

  const { isVisible } = useOnboardingContextAlertRegister(
    !!hasContextAlertRegister
  );

  const options = isLogged
    ? getLoggedMenuOptions({
        logout,
        stores: featuredStores,
        balanceAvailable: availableBalanceCashback,
      })
    : getMenuOptions({ showAuthModal: onSignInClick, stores: featuredStores });

  const handleAlertsClick = () => {
    const isAlertsPage = pathname === getWishlistUrl(locale);

    if (!isAlertsPage) return;

    reload();
  };

  const mapNotificationBarProps = (
    notificationBarProps: NotificationBarProps
  ) => {
    const { notifications } = notificationBarProps;

    const updatedNotifications = notifications.map((notification) => {
      const notificationWithImage = notification as NotificationItem;

      return {
        ...notification,
        dealImage:
          notificationWithImage.imageUrl || notificationWithImage.dealImage,
      };
    });

    const response: NotificationBarProps = {
      ...notificationBarProps,
      notifications: updatedNotifications,
    };

    return response;
  };

  const avatarUrl = isLogged
    ? userImage
    : getImagePath(locale || '', DEFAULT_UNLOGGED_AVATAR_IMAGE);

  const handleMenuClick = () => {
    triggerAlertModal();
    handleGetFeaturedStores();
  };

  // TODO: Incuindo o hasTag para tradução no tooltip de alertas gerou erro de hydration, verificar outra forma.

  return (
    <HeaderRightCornerContainer>
      {!isCommunitiesHeaderDisabled ? <HeaderCategoriesItem /> : null}
      <HeaderDynamicItemLink
        url={getAddOfferUrl(locale)}
        title={t('post-link-text')}
      >
        <HeaderList>
          <HeaderItem
            icon={<PostDealIcon icon={CirclePlus} />}
            text={t('post-link-text')}
          />
        </HeaderList>
      </HeaderDynamicItemLink>

      <HeaderDynamicItemLink
        url={getWishlistUrl(locale)}
        title={t('alerts-link-text')}
        onClick={handleAlertsClick}
      >
        <HeaderList>
          <TextIconTooltipStyle
            content={
              <p>
                <strong>Crie um alerta</strong> para o produto que você procura!
              </p>
            }
            openTo={{
              vertical: FloatingElementVerticalPosition.BOTTOM,
              horizontal: FloatingElementHorizontalPosition.LEFT,
            }}
            childrenRef={buttonAlertRef}
            icon={
              <Icon
                style={{ fontSize: 24, color: `rgb(${Theme.colors.FoxTrot})` }}
                icon={Sparkles}
              />
            }
            isVisible={isVisible}
          >
            <div ref={buttonAlertRef}>
              <HeaderItem
                icon={
                  <CountBadgeIcon
                    icon={<AlertsIcon icon={Flag} />}
                    count={alertsCount}
                  />
                }
                text={t('alerts-link-text')}
              />
            </div>
          </TextIconTooltipStyle>
        </HeaderList>
      </HeaderDynamicItemLink>

      <HeaderNotificationItem>
        <NotificationBar
          shouldNotOpen={!isLogged}
          {...mapNotificationBarProps(notificationBarProps)}
          isThemeWrapperActive={isBlackFridayHeaderToggleEnabled}
        />
      </HeaderNotificationItem>

      <CustomUserMenu
        isLogged={isLogged}
        nickname={`@${userNickname}`}
        src={typeof avatarUrl === 'string' ? avatarUrl : undefined}
        alt={t('user-menu-alt-image', userNickname)}
        menuOptions={options}
        onOpen={handleMenuClick}
      />
    </HeaderRightCornerContainer>
  );
};
