import {
  Dropdown,
  FeaturedStoresMenuItem,
  Icon,
  UserMenuInfoItem,
  UserMenuItem,
  useModal,
} from '@pelando/components';
import Link from 'next/link';
import {
  ArrowRight,
  ArrowRightToBracket,
  Bookmark,
  Gear,
  Message,
  Messages,
  Rss,
  Store,
  User,
  FileLines,
  FaceTongueMoney,
} from '@pelando/fontawesome/icons';
import { LogoutModal } from '@/presentation/components/LogoutModal';
import { mediumScreenModalStyle } from '@/presentation/styles/shared/modal';
import {
  getIsBlogPageEnabled,
  isBlackFridayLPEnabled,
  isCashbackEnabled,
  isUserbackButtonDisabled,
} from '@/presentation/services/featureFlags';
import { HTMLAttributes, useCallback } from 'react';
import { getBlogUrl } from '@/presentation/services/urls/blog';
import { BlogEvents, EventCategory } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { getMyProfileUrl } from '@/presentation/services/urls/profile';
import { useRouter } from 'next/router';
import { Langs } from '@/locales';
import { getCashbackBalanceUrl } from '@/presentation/services/urls/cashback';
import { useTranslation } from '../../../../hooks/useTranslation';
import {
  getAllStoresUrl,
  getBlackFridayUrl,
  getConductCodeUrl,
  getContactPelandoUrl,
  getCouponsUrl,
  getPelandoHelpCenterUrl,
  getPrivacyPolicyUrl,
  getSavedDealsUrl,
  getSettingsUrl,
  getTermsOfServiceUrl,
  getUserbackFeedbackUrl,
} from '../../../../services/urls';
import { ThemePickerItem } from '../ThemePickerItem';
import {
  CashbackIcon,
  Divider,
  LogoutMenuInfoItem,
  UserMenuLoggedInfoContainer,
  UserMenuUnloggedInfoContainer,
} from './styles';
import { CashbackBalance } from '../UserMenu/UserMenuCashback';

export const GO_BACK_TO_OLD_PELANDO_MENU_COMPONENT_SOURCE = '1';

type HTMLProps = HTMLAttributes<HTMLElement> & { onClick?: () => void };

type CashbakBalanceProp = { balanceAvailable: number };

export const CashbackBalanceItem = ({
  balanceAvailable,
  ...htmlProps
}: CashbakBalanceProp & HTMLProps) => {
  const cashbackEnabled = isCashbackEnabled();

  if (!cashbackEnabled) return null;
  return (
    <CashbackBalance
      {...htmlProps}
      key="balance-cashback-header"
      cashback={balanceAvailable}
      url={getCashbackBalanceUrl()}
    />
  );
};

export const MyProfileItem = (htmlProps?: HTMLProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation('header');
  return (
    <UserMenuItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-my-profile"
      icon={<Icon icon={User} />}
      label={t('menu-my-profile')}
      url={getMyProfileUrl(undefined, locale)}
    />
  );
};

export const CashbackItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');

  const cashbackEnabled = isCashbackEnabled();

  if (!cashbackEnabled) return null;

  return (
    <UserMenuItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-cashback"
      icon={<CashbackIcon />}
      label={t('menu-cashback')}
      url={getCashbackBalanceUrl()}
    />
  );
};

export const SignInSignUpItem = ({
  showAuthModal,
  ...htmlProps
}: {
  showAuthModal: () => void;
} & HTMLProps) => {
  const { t } = useTranslation('header');
  const callback = useCallback(() => {
    showAuthModal();
    htmlProps.onClick?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuthModal]);

  return (
    <UserMenuItem
      {...htmlProps}
      onClick={callback}
      data-testid="menu-signin-signup"
      icon={<Icon icon={ArrowRightToBracket} />}
      label={t('menu-signin-signup')}
    />
  );
};

export const SettingsItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-settings"
      icon={<Icon icon={Gear} />}
      label={t('menu-settings')}
      url={getSettingsUrl(locale)}
    />
  );
};

export const FollowedDealsItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-followed-deals"
      icon={<Icon icon={Rss} />}
      label={t('menu-followed-deals')}
      url={getSavedDealsUrl(locale)}
    />
  );
};

export const SavedDealsItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-saved-deals"
      icon={<Icon icon={Bookmark} />}
      label={t('menu-saved-deals')}
      url={getSavedDealsUrl(locale)}
    />
  );
};

export const ContactItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();

  return (
    <UserMenuItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-contact"
      icon={<Icon icon={Messages} />}
      label={t('menu-contact')}
      url={getContactPelandoUrl(locale)}
    />
  );
};

export const FeedbackItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  const hasUserbackButton = !isUserbackButtonDisabled();
  return (
    hasUserbackButton && (
      <UserMenuItem
        {...htmlProps}
        onClick={htmlProps?.onClick}
        data-testid="menu-feedback"
        icon={<Icon icon={Message} />}
        label={t('menu-feedback')}
        target="_blank"
        url={getUserbackFeedbackUrl(locale)}
      />
    )
  );
};

export type StoreItem = { id: string; name: string; slug: string };

type StoresItemProp = {
  stores: Array<StoreItem>;
};

export const StoresItem = ({
  stores,
  ...htmlProps
}: StoresItemProp & HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  const storesWithLink = stores.map((store) => {
    const { id, name, slug } = store;
    return { id, name, link: getCouponsUrl({ storeSlug: slug, locale }) };
  });

  return (
    <>
      <UserMenuItem
        {...htmlProps}
        onClick={htmlProps?.onClick}
        data-testid="menu-stores"
        icon={<Icon icon={Store} />}
        label={t('menu-stores')}
        url={getAllStoresUrl(locale)}
      />
      {!!storesWithLink.length && (
        <>
          <FeaturedStoresMenuItem stores={storesWithLink} />
          <Link
            href={getAllStoresUrl(locale)}
            passHref
            prefetch={false}
            legacyBehavior
          >
            <Dropdown.Item
              style={{
                padding: '24px',
                fontSize: '0.875rem',
                fontWeight: '700',
              }}
            >
              {t('featured-stores-menu-item')}
              <Icon icon={ArrowRight} />
            </Dropdown.Item>
          </Link>
        </>
      )}
      <Divider />
    </>
  );
};

export const HelpCenterInfoItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuInfoItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-help"
      label={t('menu-help')}
      url={getPelandoHelpCenterUrl(locale as Langs)}
    />
  );
};

export const TermsOfServiceInfoItem = (htmlProps: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuInfoItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-terms-services"
      label={t('menu-terms-services')}
      url={getTermsOfServiceUrl(locale)}
    />
  );
};

export const ConductCodeInfoItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuInfoItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-code"
      label={t('menu-code')}
      url={getConductCodeUrl(locale)}
    />
  );
};

export const PrivacyPolicyInfoItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');
  const { locale } = useRouter();
  return (
    <UserMenuInfoItem
      {...htmlProps}
      onClick={htmlProps?.onClick}
      data-testid="menu-privacity"
      label={t('menu-privacity')}
      url={getPrivacyPolicyUrl(locale)}
    />
  );
};

export const LogoutInfoItem = ({
  logout,
  ...htmlProps
}: { logout: () => void } & HTMLProps) => {
  const { t } = useTranslation('header');
  const { showModal, closeModal } = useModal();

  return (
    <LogoutMenuInfoItem
      {...htmlProps}
      data-testid="menu-logout"
      key="menu-settings"
      label={t('menu-logout')}
      isDanger
      onClick={() => {
        htmlProps?.onClick?.();
        showModal(<LogoutModal logout={logout} closeModal={closeModal} />, {
          style: mediumScreenModalStyle,
        });
      }}
    />
  );
};

export const BlogItem = (htmlProps?: HTMLProps) => {
  const { t } = useTranslation('header');

  const isBlogPageEnabled = getIsBlogPageEnabled();

  const handleBlogItemClick = useCallback(() => {
    emitTrackingEvent({
      name: BlogEvents.ClickToGoToBlogPage,
      category: EventCategory.Blog,
      extra: undefined,
    });

    htmlProps?.onClick?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isBlogPageEnabled) return null;

  return (
    <UserMenuItem
      {...htmlProps}
      onClick={handleBlogItemClick}
      data-testid="menu-blog"
      icon={<Icon icon={FileLines} />}
      label={t('menu-blog')}
      url={getBlogUrl()}
    />
  );
};

export const BlackFridayItem = (htmlProps?: HTMLProps) => {
  const isBlackFridayEnabled = isBlackFridayLPEnabled();

  if (!isBlackFridayEnabled) return null;

  return (
    <UserMenuItem
      {...htmlProps}
      data-testid="menu-lp-bf"
      icon={<Icon icon={FaceTongueMoney} />}
      label="Black Friday 2024"
      url={getBlackFridayUrl()}
    />
  );
};

export const MenuInfoItem = ({ logout }: { logout: () => void }) => (
  <UserMenuLoggedInfoContainer>
    <HelpCenterInfoItem />
    <TermsOfServiceInfoItem />
    <ConductCodeInfoItem />
    <PrivacyPolicyInfoItem />
    <LogoutInfoItem logout={logout} />
  </UserMenuLoggedInfoContainer>
);

export const UnloggedMenuInfoItem = () => (
  <UserMenuUnloggedInfoContainer>
    <HelpCenterInfoItem />
    <TermsOfServiceInfoItem />
    <ConductCodeInfoItem />
    <PrivacyPolicyInfoItem />
  </UserMenuUnloggedInfoContainer>
);

export const getLoggedMenuOptions = ({
  logout,
  stores,
  balanceAvailable,
}: { logout: () => void } & StoresItemProp & CashbakBalanceProp) => [
  <CashbackBalanceItem
    key="menu-cashback-balance"
    balanceAvailable={balanceAvailable}
  />,
  <MyProfileItem key="menu-my-profile" />,
  <CashbackItem key="menu-cashback" />,
  <SettingsItem key="menu-settings" />,
  <SavedDealsItem key="menu-saved-deals" />,
  <BlogItem key="menu-blog" />,
  <BlackFridayItem key="menu-blackfriday" />,
  <ContactItem key="menu-contact" />,
  <ThemePickerItem key="menu-theme" />,
  <FeedbackItem key="menu-feedback" />,
  <StoresItem key="menu-stores" stores={stores} />,
  <MenuInfoItem key="menu-user-info" logout={logout} />,
];

export const getMenuOptions = ({
  showAuthModal,
  stores,
}: {
  showAuthModal: () => void;
} & StoresItemProp) => [
  <SignInSignUpItem showAuthModal={showAuthModal} key="menu-signin" />,
  <CashbackBalanceItem key="menu-cashback-balance" balanceAvailable={0} />,
  <CashbackItem key="menu-cashback" />,
  <SettingsItem key="menu-settings" />,
  <ContactItem key="menu-contact" />,
  <BlogItem key="menu-blog" />,
  <BlackFridayItem key="menu-blackfriday" />,
  <ThemePickerItem key="menu-theme" showAuthModal={showAuthModal} />,
  <FeedbackItem key="menu-feedback" />,
  <StoresItem key="menu-stores" stores={stores} />,
  <UnloggedMenuInfoItem key="menu-user-info" />,
];
